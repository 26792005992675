<template>
    <ul class="sys_info">
        <li v-for="(item,index) in sysInfo" :key="index">
            <span>{{item.label}}</span>
            <span class="float-right" v-if='!Array.isArray(item.value)'>{{item.value}}</span>

            <span class="float-right" v-if='Array.isArray(item.value)'>
                <span v-for='(platform, index) in item.value' :key='index'>{{platform.egmPlatform.name}}</span>
            </span>
        </li>
    </ul>
</template>
<script>
export default {
    props:['sysInfo'],
    data(){
        return {

        }
    }
}
</script>