<template>
<div class="select-db">
	<v-select :disabled="disabled" v-if="render" @change="(field.onChange) ? field.onChange(item) : ''" :ref="field.key"  v-model="item[field.key]" :rules="(field.required) ? [() => !!item[field.key] || 'This field is required'] : []" :items="options" item-value="id" item-text="name" :label="field.label" outlined />
</div> 
</template>
<script>
export default {
	props: ['field', 'item'],
	data() {
		return {
			render: true,
			disabled: true,
			options: [],
		}
	},
	mounted() {

		// Is conditioned
		if(this.field.condition) {
			// The condition field changed value
			this.$watch('item.' + this.field.condition, function (state) {
				// Value is not null
				if(state) {
					this.item[this.field.key] = null; // Clear current input
					this.rerender(); // Rerender vuetify component
					this.getOptions(); // Repopulate options
				}
			});

			// Condition is not intially met so don't populate
			// anything 
			if(!this.item[this.field.condition]){
				return;
			}
		}

		this.getOptions();

	},
	methods: {

		rerender() {
			this.render = false;
			this.$nextTick(() => { 
				this.render = true;
			});
		},
		validate(state) {
			return this.$refs[this.field.key].validate(state);
		},
		getOptions() {
			axios.get(this.field.getEndpoint(this.item))
				 .then(res => {
				 	this.options = res.data;
				 	this.disabled = false;
				 })
				 .catch(err => {
				 	Vue.toasted.error('There was an error getting the information required for this page.');
				 });
		}
	}
}
</script>