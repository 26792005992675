<template>
    <div class="table_container">
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-if='canCheckbox'>
                            <v-checkbox
                                v-model='selectAll'
                                @click='$emit("selectAll", selected)'
                                dense
                            ></v-checkbox>
                        </th>
                        <th :class="(field.getHeaderClass) ? field.getHeaderClass():''" v-for="(field,index) in fields" :key="index" v-if="field.type !== 'hidden'">
                        <!-- <th v-for="(field,index) in fields" :key="index" v-if="field.type !== 'hidden'"> -->
                            {{ field.label }}
                        </th>
                        <!-- Actions -->
                        <th v-if="canDelete || canEdit" style="width: 90px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id"> 
                        <td v-if='canCheckbox'>
                             <v-checkbox
                                v-model='selected'
                                :value='item.id'
                                @click='$emit("select", selected)'
                                dense
                                multiple
                                number
                             ></v-checkbox>
                        </td>
                        <td v-for="(field,index) in fields" :key="index" v-if="field.type !== 'hidden'">
                            
                            <!-- <div class="no-link" :class="(field.getClass) ?field.getClass(item):''" v-if="!field.getLink && field.type!=='date'">{{ field.getValue(item) }}</div> -->

                            <div class="no-link" :class="(field.getClass) ?field.getClass(item):''" v-if="!field.getLink && field.type!=='date'" v-html='field.getValue(item)'></div>

                            <div class="no-link" :class="(field.getClass) ?field.getClass(item):''" v-if="!field.getLink && field.type === 'date' ">{{ field.getValue(item) | moment("h:mm:ss")}}</div>

                            <router-link class="link"  v-if="field.getLink" :to="field.getLink(item)" > {{ field.getValue(item) }}</router-link> 
                            
                        </td>
                        <!-- Actions -->
                        <td   v-if="canDelete || canEdit" style="width: 90px;">
                            <a v-if="canEdit" @click="$emit('edit', item)">
                                <v-icon color="gray">mdi-pencil-outline</v-icon>
                            </a>
                            <a v-if="canDelete" @click="$emit('delete', item)">
                                <v-icon color="gray">mdi-delete-outline</v-icon>
                            </a>
                        </td>
                    </tr>
                </tbody> 
            </template>
        </v-simple-table>
    </div>
</template>
<script>
export default {
    props: ['fields', 'data', 'canEdit', 'canDelete' , 'canCheckbox'],
    data() {
        return {
            selected: []
        }
    },
    computed: {
        selectAll: {
            get() {
                if (this.data && this.data.length > 0) { // A users array exists with at least one item
                let allChecked = true;

                for (const item of this.data) {
                    if (!this.selected.includes(item.id)) {
                    allChecked = false; // If even one is not included in array
                    }
                    
                    // Break out of loop if mismatch already found
                    if(!allChecked) break;
                }

                return allChecked;
                }

                return false;
            },
            set(value) {
                const checked = [];

                if (value) {
                this.data.forEach((item) => {
                    checked.push(item.id);
                });
                }

                this.selected = checked;
            }
        },
    }
}
</script>
<style>
.v-input--checkbox {
    margin-top: 0px !important;
}
.v-input--selection-controls__input{
    margin-right: 0px !important;
}
.v-messages {
    min-height: 0px !important;
}
</style>