<template>
    <ul>
        <router-link v-for="(item,index) in tabNames" :key="index" :to="item.route">
            <li  v-ripple @click="changeTab(item)" v-bind:class="{tab_active:isActive === item.route}">{{item.label}}</li>
        </router-link>
    </ul>   
</template>
<script>
export default {
    props:['tabNames'],
    data() {
        return {
            isActive:''
        }
    },
    mounted(){
        for(let i=0;i<this.tabNames.length;i++){
            if(this.$route.path.includes(this.tabNames[i].route)){
                this.isActive = this.tabNames[i].route;
                break;
            }
        }
        
    },
    methods: {
        changeTab(item){
            this.isActive = item.route;
        }
    },
    watch: {
        $route(to,from){
            for(let i=0;i<this.tabNames.length;i++){
                if(to.path.includes(this.tabNames[i].route)){
                    this.isActive = this.tabNames[i].route;
                }
            }
        }
    }
}
</script>