<template>
    <div class="crud-zone">
        <div class="card_header">
            <span class="float-left">{{ label }}</span>
            <span class="float-right">
                <span v-if="canAdd" v-on:click="openAddModal()">Add new <v-icon>mdi-plus</v-icon></span>
            </span>
        </div>
        <crud-table v-if="data" class="gen_table" :fields="fields" :data="data" :canEdit="canEdit" :canDelete="canDelete" @edit="openEditModal($event)" @delete="openDeleteModal($event)"></crud-table>
        <div class="dialogs">
            <!-- Add/Edit Dialog -->
            <v-dialog v-if="temp" v-model="editing" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>{{label}}</p>
                        <p v-if="!temp.id">Add New</p>
                        <p v-if="temp.id">Edit</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text>
                        <br>
                        <form>
                            <div class="fields">
                                <div class="field" v-for="(field, index) in fields" :key="index">

                                    <!-- Has Condition --> 
                                    <div class="conditioned" v-if="field.condition">                                        
                                            <v-text-field :ref="field.key" :rules="(field.required) ? [() => !!temp[field.key] || 'This field is required'] : []" v-if="field.type == 'text'" v-model="temp[field.key]" :label="field.label" outlined /> 
                                            <select-db :ref="field.key" @change="temp[field.key] = $event" :item="temp" :field="field" v-if="field.type == 'select-db'" />    
                                    </div>
                                 
                                    <!-- No condition -->
                                    <div class="not-conditioned" v-if="!field.condition">
                                        <v-text-field :ref="field.key" :rules="(field.required) ? [() => !!temp[field.key] || 'This field is required'] : []" v-if="field.type == 'text'" v-model="temp[field.key]" :label="field.label" outlined /> 
                                        <select-db :ref="field.key" @change="temp[field.key] = $event" :item="temp" :field="field" v-if="field.type == 'select-db'" />     
                                    </div>

                                    <input type="hidden" v-model="temp[field.key]" >
                                    
                                </div> 
                            </div>
                        </form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :disabled="loading" v-if="!temp.id" :elevation="0" color="green" dark @click="createItem(temp)">
                            Create
                        </v-btn>
                        <v-btn class="modal_btn" :disabled="loading" v-if="temp.id" :elevation="0" color="green" dark @click="updateItem(temp, false)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Delete Modal -->
            <v-dialog v-model="deleting" width="550">
                <v-card>
                    <div class="modal_header">
                        <p>{{label}}</p>
                        <p>Delete Item</p>
                    </div>
                    <v-divider class="modal_divider"></v-divider>
                    <v-card-text class="modal_body">
                        <p>Are you sure you want to delete this item ?</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="modal_actions">
                        <v-spacer></v-spacer>
                        <v-btn class="modal_btn" :elevation="0" color="#ada7a7" dark @click="closeModals">
                            Cancel
                        </v-btn>
                        <v-btn class="modal_btn" :elevation="0" color="red" dark @click="deleteItem(selected)">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div> 
    </div>
</template>
<style lang="scss">
    .v-card__text{
        max-height: 400px;
        overflow-y: scroll;
    }



    /* width */
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #c2c2c2; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }


</style>
<script>
import CrudTable from '@/components/tables/CrudTable.vue';
import SelectDB from '@/components/crud/SelectDB.vue';
export default {
    props: ['label', 'endpoint', 'fields', 'data', 'canEdit', 'canDelete', 'canAdd'],
    components: {
        'crud-table': CrudTable,
        'select-db': SelectDB,
    },
    data() {
        return {
            editing: false,
            deleting: false,
            selected: null, 
            temp: {},
            loading: false,
        }
    },
    mounted() {

    },
    watch: {
        editing: function(editing) {

            if (editing === false) {
                this.temp = null;
            }
        },
    },
    methods: {


        validate() {

            let valid = true;

            this.fields.forEach(field => {
                // Field is requried
                if(field.required) {
                
                    let fieldValid = this.$refs[field.key][0].validate(true); 
                    if(!fieldValid) { 
                        valid = false;
                        console.log('invalid', field);
                    }

                }
            });

            return valid;
        },

        createItem(item, keepOpen) {

            // Validation 
            this.loading = true;            

            if(!this.validate()) {
                Vue.toasted.error('Please fill in the form');
                this.loading = false;
                return; 
            }


            axios.post(this.endpoint, item)
                 .then(res => {

                    Vue.toasted.success('Item created.');
                    this.$emit('refresh');

                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },

        updateItem(item, keepOpen) {

            // Validation 
            this.loading = true;            

            if(!this.validate()) {
                Vue.toasted.error('Please fill in the form');
                this.loading = false;
                return; 
            }

            // Remove all child objects
            // from the model before seding it to the API
            var cleanItem = Object.assign({}, item);
            for (let prop in cleanItem) {
                if(typeof cleanItem[prop] == 'object') {
                    delete cleanItem[prop];
                }
            } 

            axios.put(this.endpoint + item.id, cleanItem)
                 .then(res => {

                    Vue.toasted.success('Item created.');
                    this.$emit('refresh');

                    if(!keepOpen){
                        this.closeModals();
                    }

                    this.loading = false;
                    
                 })
                 .catch(err => {

                    if(err.response.data.title) {
                        Vue.toasted.error(err.response.data.title); 
                    } else {
                        Vue.toasted.error('There was an error processing your request'); 
                    }

                    this.loading = false;

                 });

        },

        deleteItem(item) {

            axios.delete(this.endpoint + item.id)
                .then(res => {
                    Vue.toasted.success('Item deleted.');
                    this.closeModals();
                    this.$emit('refresh');
                })
                .catch(err => {
                    Vue.toasted.error('There was an error deleting this item.')
                });

        },
        closeModals() {
            this.temp = null;
            this.selected = null;
            this.editing = false;
            this.deleting = false;
        },
        openAddModal() {

            this.temp = {};

            // Copy hidden fields over to our temp object
            let hidden = this.fields.filter(element => element.type === 'hidden');
            hidden.forEach(field => {
                this.temp[field.key] = field.getValue(this.temp);
            });

            this.editing = true;
            
        },
        openEditModal(item) {
            this.temp = Object.assign({}, item);
            this.editing = true;
        },
        openDeleteModal(item) {
            this.selected = item;
            this.deleting = true;
        }
    }

}
</script> 